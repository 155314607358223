import * as React from "react"
import '../../gatsby-browser.js';
import Into from "../static/img/intro.png";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import WhatAbout from "../component/Whatwedo/WhatAbout"
import WhatServices from "../component/Whatwedo/WhatServices";
import { motion } from "framer-motion"

import axios from 'axios';
import {url} from "../url/url";
import MetaTags from "../component/MetaTags.jsx";


class Whatwedo extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[],
            loading:true,
            main:[],
        }

        this.id = ""
        this.main = [];
    }

    componentDidMount() {

        axios({
            method: 'GET',
            url:url+'/services/',
        })
            .then((result) => {
                this.setState({
                    data: result.data,
                    loading: false,
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }


    render(){

        const pageVariants = {
            initial: {
                opacity: 0,
            },
            in: {
                opacity: 1,
            },
            out: {
                opacity: 0,
            },
        }

        const {data, loading } = this.state;

        this.id = this.props.location.pathname.split('/')[2]

        return(
            <>
                <Navbar />
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                >

                    {loading ? (
                        <> <p> Loading.. </p> </>
                    ) : (
                        <>
                            <section id="whatwedo" className="whatwedo">
                                <div align="center">
                                    <h1 className="whatwedo"> hello 123
                                        <i className="fa fa-quote-left" aria-hidden="true"></i> &nbsp;
                                        What We Do In &nbsp;
                                        <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    </h1>
                                </div>
                            </section>

                            {/*<WhatAbout values={data} />*/}
                            {/*<WhatServices values={data} />*/}
                        </>
                    )}

                    <Footer />
                </motion.div>
                <MetaTags title={"What We Do? | Koderbox"} />

            </>
        )
    }
}

export default Whatwedo