import * as React from "react"
import '../../../gatsby-browser';

import Soft from "../../static/img/soft.png";
import {url} from "../../url/url";

class WhatService extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){

        let data = this.props.values;

        return(
            <>

                <section id="services" className="section-bg services">
                    <div className="container">

                        <header className="section-header">
                            <h3>Our Services</h3>
                            <p>
                                <hr />
                            </p>
                        </header>

                        <div className="row">

                            <div className="col-md-12  wow bounceInUp " data-wow-duration="1.4s">
                                <div className="box whatservice">

                                    <p className="description">
                                        {data.information}
                                        <br/><br/>
                                    </p>

                                </div>
                            </div>


                        </div>

                    </div>
                </section>

            </>
        )
    }
}

export default WhatService