
import * as React from "react"
import '../../../gatsby-browser';

import Services from '../../static/img/img/work.png'

class WhatAbout extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    componentDidMount() {
    }

    render(){

        const data = this.props.values

        // console.log(data)

        return(
            <>

                <section id="about">
                    <div className="container">
                        <div className="row about-extra">
                            <div className="col-lg-6 wow fadeInUp">
                                <img src={Services} className="img-fluid" alt="" width="400" />
                            </div>
                            <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                                <h2>What We Do {data.name}</h2>
                                <p>
                                    {data.about}
                                </p>

                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default WhatAbout